@import (less) "../../../../css/variables.less";

.nervePanel .moduleHeader > div {
    margin: 5px;
}

.nervePanel .entitiesList > li > input {
    margin: 6px 5px 0 0;
}

.entitiesList .nav {
    margin-bottom: 4px;
    padding-left: 30px;
}

.entitiesList .nav > span {
    background: none !important;
    border: none !important;
}