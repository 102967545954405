.relationsList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.relationsList li {
    padding: 3px 5px;
}

.relationsList li:hover {
    background-color: #eee !important;
    cursor: pointer;
}

.relationsList li {
    background-color: #fff;
}

.relationsList li.selected {
    background-color: #d3d3d3 !important;
}

.triplesDialog h2 {
    padding: 3px;
    background-color: #ccc;
    border-bottom: 1px solid #999;
}

.triplesDialog .columns {
    width: 100%;
    height: 80%;
}

.triplesDialog .column {
    border: 1px solid #999;
    height: 100%;
    display: flex;
    flex-direction: column;
    float: left;
    width: 31%;
    margin-right: 1%;
}

.triplesDialog .currentRelation {
    height: 15%;
    text-align: center;
}

.triplesDialog .currentRelation p {
    margin: 5px;
    font-size: 1em;
}

.triplesDialog .column>ul {
    flex: 1;
    overflow: auto;
}

.triplesDialog .column>ul li {
    list-style: none;
    margin: 0;
}

.triplesDialog .entitiesList li.selected {}

.triplesDialog .column.predicate ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.triplesDialog .column.predicate li {
    padding: 3px;
    background-color: #fff;
}

.triplesDialog .column.predicate li:hover {
    background-color: #ddd;
    cursor: pointer;
}

.triplesDialog .column.predicate li.selected {
    background-color: #ccc;
}

.triplesDialog .customEntry {
    border-top: 1px solid #999;
    background-color: #ccc;
    padding: 2px;
    margin: 0;
    text-align: center;
}

.triplesDialog .customEntry input {
    width: 95%;
    text-align: left;
}