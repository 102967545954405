.loadingIndicatorDialog .progressBar {
  position: relative;
}

.loadingIndicatorDialog .progressLabel {
  position: absolute;
  left: 30%;
  top: 4px;
}

.loadingIndicatorDialog .ui-progressbar-value {
  background: #7AA9FF !important;
  margin: 0 !important;
}