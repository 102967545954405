.ui-layout-resizer {
    /* all 'resizer-bars' */
    border: 0px solid #BBB !important;
    background: #f5f5f5 !important;
}

.ui-layout-toggler-east-closed,
.ui-layout-toggler-west-open {
    background: url("../images/layout_arrow_left.png") no-repeat scroll center center !important;
}

.ui-layout-toggler-east-open,
.ui-layout-toggler-west-closed {
    background: url("../images/layout_arrow_right.png") no-repeat scroll center center !important;
}

.ui-layout-toggler-north-open,
.ui-layout-toggler-south-closed {
    background: url("../images/layout_arrow_up.png") no-repeat scroll center center !important;
}

.ui-layout-toggler-north-closed,
.ui-layout-toggler-south-open {
    background: url("../images/layout_arrow_down.png") no-repeat scroll center center !important;
}

.ui-layout-toggler {
    border: 0px solid #BBB;
    opacity: 0.6;
}

.ui-layout-toggler:hover {
    opacity: 1;
}