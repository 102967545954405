
.cwrcWrapper {
    margin: 0;
    padding: 0;
    font-family: Lato,Arial,Helvetica,sans-serif;
    font-size: 14px;
    color: #333;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.cwrcDialogWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
}

.cwrcLoadingMask {
    width: 100%;
    height: 100%;
    background-color: #DDD;
    position: absolute;
    z-index: 1000;
}

.cwrcLoadingMask div {
    background-image: url(../../images/loading_large.gif);
    background-repeat: no-repeat;
    background-position: left center;
    height: 32px;
    font-size: 1.2em;
    padding-top: 6px;
    padding-left: 38px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cwrcHeader {
    overflow: hidden !important;
    color: #eee !important;
    background-color: #445372 !important;
}

.cwrcFooter {
    overflow: hidden !important;
    background-color: #ddd !important;
    border-top: 1px solid #bbb !important;
}

.cwrcFooter a {
    font-size: 12px;
    color: #333 !important;
    text-decoration: none;
    display: block;
    float: right;
    margin-right: 10px;
}
.cwrcFooter a:hover {
    text-decoration: underline;
}

.headerParent {
    display: table;
    height: 35px;
    width: 100%;
    overflow: hidden;
}

.fullscreenLink.out {
    padding-left: 20px;
}

.fullscreenLink.in {
    padding-left: 20px;
}

.cwrc .moduleParent {
    height: 100%;
    display: flex;
    flex-direction: column;

    background-color: #f5f5f5;
}

.cwrc .moduleContent {
    padding-top: 5px;
    overflow: auto;
    flex: 1;
}

.cwrc .moduleHeader {
    border-bottom: 1px solid #dddd;
    padding: 10px;
}

.cwrc .moduleFooter {
    border-top: 1px solid #ddd;
    padding: 8px;
    display: flex;
    justify-content: space-between;
}

.cwrc .moduleHeader > div, .cwrc .moduleFooter > div {
    margin: 5px;
}


.cwrc.tabs {
    overflow: hidden;
    padding: 0 !important;
}

.cwrc.tabs > ul {
    padding: 3px 2px 0 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;

    /* border-bottom: 1px solid #bbbbbb !important; */
    border-bottom: 0px solid #bbbbbb !important;
    /* background: #e0e0e0 !important; */
}

.cwrc.tabs > ul li a {
    padding: 4px 1em !important;
    outline: 0 !important;
}

.cwrc.tabs .ui-tabs-panel {
    padding: 0 !important;
    height: 100%;
    overflow: auto;
}

.cwrc.tabs > ul li.ui-state-default {
    border-color: #dedede !important;
    background: #ececec !important;
}

.cwrc.tabs > ul li.ui-state-hover {
    background: #fafafa !important;
}

.cwrc.tabs > ul li.ui-state-active {
    border-color: #bbbbbb !important;
    background: #ffffff !important;
}

.cwrc.tabs > ul li.ui-state-active a, .cwrc.tabs > ul li.ui-state-active a:link {
    color: #333333 !important;
}


.loading {
    background-image: url('../../images/loading.gif');
    width: 16px;
    height: 16px;
    display: inline-block;
}