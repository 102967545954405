.attributeWidget {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.attributeWidget label {
    display: inline;
}

/* .attributeWidget .attributeSelector {
    flex: 1;
    display: flex;
    flex-direction: column;
} */

.attributeSelector {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* 
.attributeWidget .attributeSelector ul {
    flex: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
} */

.attributeSelector ul {
    flex: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
}

/* .attributeWidget .attributeSelector li {
    list-style-type: none;
    margin: 0;
    padding: 4px;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 1px;
    margin-bottom: 1px;
} */

.attributeSelector li {
    list-style-type: none;
    margin: 0;
    padding: 4px;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 1px;
    margin-bottom: 1px;
}

.annotationDialog span.fullname {
    font-size: 0.75rem;
    color: #666;
    text-transform: capitalize;
}

/* break line */
/* .attributeWidget .attributeSelector li span.fullname::before {
    content: "\a";
    white-space: pre;
} */

.attributeSelector li span.fullname::before {
    content: "\a";
    white-space: pre;
}

/* .attributeWidget .attributeSelector li:hover {
    background-color: #f0f0f0;
    cursor: pointer;
} */

.attributeSelector li:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

/* .attributeWidget .attributeSelector li.selected {
    background-color: #eee;
} */

.attributeSelector li.selected {
    background-color: #4688f040;
}

.attributeWidget .attsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 2;
    /* overflow: auto; */
}

.attributeWidget .atts {
    float: left;
    clear: left;
}

.attributeWidget .attsContainer input,
.attributeWidget .attsContainer select {
    width: 100% !important;
}